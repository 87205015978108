import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-redux'
import Select, { components } from 'react-select'
import Checkbox from 'uiKit/Checkbox'
import { CarersSelectorStyles } from './carersSelectorStyles'
import { ActionButton, CarersSelectMenuHeader, CarersSelectTotalChecked } from './CarersSelect.styles'
import { OptionsType, OptionTypeBase } from 'react-select/src/types'
import CarersSelectAllModal from '../CarersSelectAllModal/CarersSelectAllModal'
import { createServiceUser, getServiceUsers } from '../../api/serviceUsersApi'
import { ServiceUserType } from 'models/ServiceUsersTypes'

const disabledButtonBG = 'linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), var(--White-label-color-secondary, #228CB8)'

interface Props {
  user?: ServiceUserType
  botId: string
  assignedProfessionals?: number[]
  onChangeHandler?: (value: any) => void
  isMultipleFlow?: boolean
  onMultipleChangeHandler?: (value: any, method: 'POST' | 'DELETE') => void
  placeholder: string
}

const CarersSelect: FC<Props> = ({
  user,
  botId,
  assignedProfessionals,
  onChangeHandler,
  isMultipleFlow = false,
  onMultipleChangeHandler,
  placeholder
}) => {
  const store = useStore()
  const { serviceUsersTab: { careProfessionals, currentPage } } = store.getState()
  const defaultProfessionals = () => careProfessionals.filter(professional => assignedProfessionals?.includes(professional.value))
  const [selectedValue, setSelectedValue] = useState(defaultProfessionals)
  const [isOpenSelectAllModal, setIsOpenSelectAllModal] = useState(false)
  const isAllSelected = useMemo(() => selectedValue.length === careProfessionals.length, [selectedValue, careProfessionals])
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  useEffect(() => {
    setSelectedValue(defaultProfessionals)
  }, [assignedProfessionals])

  const Menu = useCallback((props) => {
    return (
      <components.Menu {...props}>
        <CarersSelectMenuHeader>
          <Checkbox
            checked={isAllSelected}
            label='Select all'
            onChange={selectAllHandler}
          />
          {isMultipleFlow && (
            <>
              <ActionButton
                disabled={!selectedValue.length}
                onClick={() => onMultipleChangeHandler(selectedValue, 'POST')}
              >
                Assign
              </ActionButton>
              <ActionButton
                backgroundColor={disabledButtonBG}
                disabled={!selectedValue.length}
                onClick={() => onMultipleChangeHandler(selectedValue, 'DELETE')}
              >
                Remove
              </ActionButton>
            </>
          )}
        </CarersSelectMenuHeader>
        {props.children}
      </components.Menu>
    )
  }, [selectedValue, isAllSelected])

  const MenuList = useCallback((props) => {
    const listQuantity = props.children.length
    const title = listQuantity > 1 ? 'users' : listQuantity === 1 && 'user'
    return (
      <components.MenuList {...props}>
        <CarersSelectTotalChecked>
          {listQuantity} {title}
        </CarersSelectTotalChecked>
        {props.children}
      </components.MenuList>
    )
  }, [])

  const Option = useCallback((props) => {
    return (
      <components.Option {...props}>
        <Checkbox
          checked={props.isSelected}
          label={props.children}
          onChange={() => undefined}
        />
      </components.Option>
    )
  }, [])

  const selectAllHandler = () => {
    if (isMultipleFlow) {
      const selectedProfessionals = isAllSelected ? [] : careProfessionals
      setSelectedValue(selectedProfessionals)
    } else {
      setIsOpenSelectAllModal(true)
    }
  }

  const handleSelect = (value: OptionsType<OptionTypeBase>) => {
    onChangeHandler && onChangeHandler(value)
    setSelectedValue(value)
    if (!onChangeHandler && !isMultipleFlow) {
      const userData = {
        ...user,
        assignedProfessionals: value.map(professional => professional.value)
      }
      createServiceUser(botId, userData).then(() => {
        getServiceUsers(botId, currentPage)
      })
    }
  }

  const handleProceed = () => {
    const selectedProfessionals = isAllSelected ? [] : careProfessionals
    setIsOpenSelectAllModal(false)
    setSelectedValue(selectedProfessionals)

    if (!onChangeHandler && !isMultipleFlow) {
      const userData = {
        ...user,
        assignedProfessionals: selectedProfessionals.map(professional => professional.value)
      }
      createServiceUser(botId, userData).then(() => {
        getServiceUsers(botId, currentPage)
      })
    }
  }

  const handleCloseMenu = () => {
    if (isMultipleFlow) {
      setSelectedValue([])
    }
  }

  return (
    <>
      <Select
        isMulti
        closeMenuOnSelect={false}
        value={selectedValue}
        onChange={handleSelect}
        options={careProfessionals}
        styles={CarersSelectorStyles}
        placeholder={placeholder}
        noOptionsMessage={() => 'This list is empty.'}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        components={{ Menu, MenuList, Option }}
        menuIsOpen={isOpenMenu}
        autoFocus={false}
        isClearable={false}
        controlShouldRenderValue={!isMultipleFlow}
        onFocus={() => setIsOpenMenu(true)}
        onBlur={() => !isOpenSelectAllModal && setIsOpenMenu(false)}
        onMenuClose={handleCloseMenu}
      />

      {isOpenSelectAllModal &&
        <CarersSelectAllModal
          open={isOpenSelectAllModal}
          onClose={() => setIsOpenSelectAllModal(false)}
          onProceed={handleProceed}
        />
      }
    </>
  )
}

export default CarersSelect
