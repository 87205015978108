import React, { useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import MessageGroup from '../MessageGroup/MessageGroup'

import { getMessages, getAllMessages, setMessageRead } from '../../api/user'
import { saveSupportUserMessages, saveSupportUserMoreMessages } from '../../actions/activeUser'

import { styles } from './styles'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { formatDate } from 'helpers/formatDate/formatDate'
import { updateUserUnreadMessages } from '../../actions/users'
import { usePrevious } from 'hooks/usePrevious'
import { isElementTop } from 'helpers/isElementTop'
import { ChatUserType } from 'models/ChatMessagesTypes'
import { SupportUserType } from 'models/SupportUserType'

interface Props {
  classes: any
  user: ChatUserType & SupportUserType
  activeBot: any
  searchQuery: string
  chatId: string
}

const MessagesWrap: React.FC<Props> = (props) => {
  const { classes, user, activeBot, searchQuery, chatId } = props
  const [scrollPosition, setScrollPosition] = useState(null)
  const [scrollToTop, setScrollToTop] = useState(false)
  const [dates, setDates] = useState([])
  const [page, setPage] = useState(0)
  const [hasNext, setHasNext] = useState(false)
  const [loading, setLoading] = useState(true)
  const scrollContainer = useRef(null)
  const messagesEnd = useRef(null)
  const messageIntoView = useRef(null)
  const prevUserMessagesLength = usePrevious(user.messages?.length)

  useEffect(() => {
    if (chatId && activeBot?.id) {
      getAllMessages(chatId, activeBot.id)
        .then(saveSupportUserMessages)
        .then(() => {
          setLoading(false)
        })
        .then(() => {
          scrollToBottom()
          messageIntoView.current.scrollIntoView()
        })
        .catch(() => setLoading(false))
    }
  }, [chatId])

  useEffect(() => {
    if (scrollToTop && scrollPosition) {
      scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight - scrollPosition
    }

    if (user.messages?.length) {
      updateMessageDates()
      updateMessageRead()

      if (user.messages?.length - prevUserMessagesLength === 1 && !searchQuery) {
        scrollToBottom()
      }
    } else {
      setDates([])
    }
  }, [user.messages?.length, user.chatId])

  useEffect(() => {
    if (searchQuery && user.messages?.length) {

      const matchedMessage = user.messages
        .filter(msg => msg.nativeMessage?.includes(searchQuery))
        .reduce((minMsg, currentMsg) => (currentMsg.chatHistoryId < minMsg.chatHistoryId ?
          currentMsg : minMsg), user.messages[0])

      if (matchedMessage && messageIntoView.current) {
        messageIntoView.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [searchQuery, user.messages])

  const updateMessageDates = () => {
    const dates = user.messages.map(item => item.lastEventAt?.split('T')[0])
    setDates([...new Set(dates)])
  }

  const updateMessageRead = () => {
    const lastMessage = user?.messages[0]

    if (lastMessage?.chatHistoryId && user?.chatId) {
      setMessageRead(user.chatId, activeBot.id).then(() => {
        updateUserUnreadMessages(user)
      })
    }
  }

  const scrollToBottom = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView()
    }
  }

  const handleScroll = ({ target }) => {
    if (isElementTop(target) && hasNext && target === scrollContainer.current && user.chatId) {
      setScrollPosition(scrollContainer.current.scrollHeight)
      setScrollToTop(true)
      getAllMessages(user.chatId, activeBot.id)
        .then(saveSupportUserMoreMessages)
        .then(data => {
          setPage(page + 1)
          updateHasNext(data.content.length)
        })
        .then(scrollToBottom)
        .catch(() => setLoading(false))
    }
  }

  const updateHasNext = (messagesLength) => {
    setHasNext(messagesLength === 20)
  }

  return (
    <>
      {loading ? (
        <div className={classes.loaderWrap}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <div className={classes.container} ref={scrollContainer} onScroll={handleScroll}>
          {dates
            .slice()
            .reverse()
            .map((date) => (
              <div key={date}>
                <p className={classes.date}>{formatDate(date)}</p>
                <MessageGroup
                  date={date}
                  messages={user.messages}
                  messageIntoView={messageIntoView}
                  searchQuery={searchQuery}
                />
              </div>
            ))}
          <div className={classes.bottomScrollElem} ref={messagesEnd} />
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.activeUser,
  activeBot: state.activeBot,
  searchQuery: state.searchQuery.searchQuery,
})

export default withStyles(styles)(connect(mapStateToProps)(MessagesWrap))
