import React, { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useStore } from 'react-redux'
import { alertError } from 'api'
import { ServiceUserType } from 'models/ServiceUsersTypes'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import Checkbox from '../Checkbox/Checkbox'
import MultipleEditBar from '../MultipleEditBar/MultipleEditBar'
import CarersSelect from '../CarersSelect/CarersSelect'
import CarePlans from '../CarePlans/CarePlans'
import { setAllServiceUsersId, setSelectedServiceUsersId } from '../../actions/serviceUsersActions'
import { ExportConversations } from '../ExportConversations/ExportConversations'
import { InputEditor } from '../InputEditor/InputEditor'
import { DuplicateWarningMessage } from './ServiceUsersTable.styles'
import { deletePatientCarePlan, getServiceUsers, uploadPatientKnowledge } from '../../api/serviceUsersApi'


interface Props {
  botId: string
  setDeleteUserModal: Dispatch<SetStateAction<{ isOpen: boolean, selectedUser: ServiceUserType | null }>>
}

export const ServiceUsersTable: FC<Props> = ({
  botId,
  setDeleteUserModal
}) => {
  const store = useStore()
  const { serviceUsersTab: { serviceUsers, selectedServiceUsersId, currentPage } } = store.getState()
  const isChecked = useCallback((userId) => selectedServiceUsersId.includes(userId), [selectedServiceUsersId])

  const onCheckboxChange = (value) => {
    setAllServiceUsersId()
  }

  const columnTitles = useMemo(() => ([
    {
      component: (
        <Checkbox
          checked={selectedServiceUsersId.length === serviceUsers.length}
          onChange={onCheckboxChange}
          color='var(--color-button-primary)'
        />
      ),
      maxWidth: 50
    },
    { title: 'First Name', maxWidth: 160 },
    { title: 'Last Name', maxWidth: 160 },
    { title: 'Care Professionals', maxWidth: 320 },
    { title: 'Care plans', maxWidth: 240 },
    { title: 'Export conversations logs', maxWidth: 220 },
    { title: 'Action', maxWidth: 50 }
  ]), [selectedServiceUsersId.length])

  const singleCheckboxSelect = (userId: number) => {
    setSelectedServiceUsersId(userId)
  }

  const deleteCarePlan = (sourceId: string) => {
    deletePatientCarePlan(botId, sourceId).then(() => {
      getServiceUsers(botId, currentPage)
    })
  }

  const uploadCarePlan = (event: ChangeEvent<HTMLInputElement>, patientId: number, carePlans) => {
    const newFiles = Array.from(event.target.files)
    const duplicates = newFiles
      .map(file => file.name)
      .filter(newFileName => carePlans?.map(plan => plan.originalFilename).includes(newFileName))
    const formData = new FormData()
    if (newFiles.length) {
      newFiles.forEach((file) => {
        formData.append('files', file as Blob)
      })
    }

    if (duplicates.length) {
      const message = `File${duplicates.length > 1 ? 's' : ''} ${duplicates.join(', ')} is already added.`
      alertError(message)
    } else {
      uploadPatientKnowledge(botId, patientId, formData).then(() => {
        getServiceUsers(botId, currentPage)
      })
    }
  }

  const openInNewWindow = (link: string) => {
    window.open(link, '_blank')
  }

  return (
    <Table
      titles={columnTitles}
    >
      <MultipleEditBar
        botId={botId}
        showMultipleBar={!!selectedServiceUsersId.length}
        selectedServiceUsersId={selectedServiceUsersId}
        setDeleteUserModal={setDeleteUserModal}
      />
      {serviceUsers.map((user: ServiceUserType) => (
        <TableRow
          key={user.id}
          style={{
            alignItems: 'start',
            background: isChecked(user.id) ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #00A0E3' : '',
            position: 'relative',
            ...user.duplicatePatient && { minHeight: 124 }
          }}
        >
          <TableCell style={{ maxWidth: columnTitles[0].maxWidth, marginTop: 12 }}>
            <Checkbox
              checked={isChecked(user.id)}
              onChange={() => singleCheckboxSelect(user.id)}
              color='var(--color-button-primary)'
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[1].maxWidth, flexDirection: 'column' }}>
            <InputEditor
              user={user}
              value={user.firstName}
              userKey='firstName'
              botId={botId}
            />
            {user.duplicatePatient &&
              <DuplicateWarningMessage>
                This name combination already exists. Delete duplicate or add indicator (e.g. middle name) to differentiate service user.
              </DuplicateWarningMessage>
            }
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[2].maxWidth }}>
            <InputEditor
              user={user}
              value={user.lastName}
              userKey='lastName'
              botId={botId}
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[3].maxWidth }}>
            <CarersSelect
              user={user}
              botId={botId}
              assignedProfessionals={user.assignedProfessionals}
              placeholder='Assign care professionals'
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[4].maxWidth }}>
            <CarePlans
              carePlans={user.carePlans}
              onDeleteAction={deleteCarePlan}
              onUploadAction={(e) => uploadCarePlan(e, user.id, user.carePlans)}
              onOpenInNewWindowAction={openInNewWindow}
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[5].maxWidth }}>
            <ExportConversations
              botId={botId}
              userId={user.id}
            />
          </TableCell>
          <TableCell style={{ maxWidth: columnTitles[6].maxWidth, marginTop: 9 }}>

          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
