import React, { FC } from 'react'
import Modal from '@material-ui/core/Modal'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import { BaseButton } from 'uiKit/buttons/BaseButton/BaseButton'
import { CrossIcon } from 'uiKit/icons/CrossIcon'
import { ButtonsWrapper, Title } from './CarersSelectAllModal.styles'
import classes from '../../../nlp/components/knowledgeBase/SourcesTable/styles.module.scss'

interface Props {
  open: boolean
  onClose: () => void
  onProceed: () => void
}

const CarersSelectAllModal: FC<Props> = ({
  open,
  onClose,
  onProceed
}) => {

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <Title>
          By selecting all care professionals in this list, you are automatically assigning them to selected service
          user(s).
        </Title>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={'var(--color-spun-pearl-grey)'} />
        </div>
        <ButtonsWrapper>
          <BaseButton title='Cancel' onClick={onClose} />
          <SubmitButton onClick={onProceed} title='Proceed' />
        </ButtonsWrapper>
      </div>
    </Modal>
  )
}

export default CarersSelectAllModal
