import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import classes from './styles.module.scss'
import './dateRangePickerStyles.css'
import classNames from 'classnames'

interface Params {
  setDate: any
  startDate: any
  endDate: any
  customClasses?: any
}

const DateRange: React.FC<Params> = ({ setDate, startDate, endDate, customClasses }) => {
  const saveChanges = (event, picker) => {
    setDate(picker.startDate, picker.endDate)
  }

  return (
    <div className={classNames(classes.dateRangePickerWrap, customClasses)}>
      <DateRangePicker startDate={startDate} endDate={endDate} onApply={saveChanges}>
        <input
          className={classes.input}
          type="text"
          name="daterange"
          value={`${startDate.format('YYYY-MM-DD')} - ${endDate.format('YYYY-MM-DD')}`}
          onChange={e => e}
        />
      </DateRangePicker>
    </div>
  )
}
export default DateRange
