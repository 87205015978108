import React, { memo, useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Message from '../Message'

import { styles } from './styles'

const MessageGroup = props => {
  const { classes, messages, date, messageIntoView, messageId, reverse, hash, searchQuery } = props

  const setRef = id => {
    const message = messages.find(msg => msg.chatHistoryId === id)
    const parsedMessage = JSON.parse(message.nativeMessage)

    if (parsedMessage?.message?.text?.includes(searchQuery)) {
      const messageWithLowestId = messages
        .filter(msg => JSON.parse(msg.nativeMessage)?.message?.text?.includes(searchQuery))
        .reduce((minMsg, currentMsg) => (currentMsg.chatHistoryId < minMsg.chatHistoryId ? currentMsg : minMsg),
          messages[0])

      return messageWithLowestId.chatHistoryId === id ? messageIntoView : null
    }

    return id?.toString() === hash?.substring(1) || id === messageId ? messageIntoView : null
  }

  const renderMessages = messages
    ?.slice()
    ?.reverse()
    ?.filter(msg => msg?.lastEventAt && msg?.lastEventAt?.split('T')[0] === date)

  const lastReactionElementIndex = useMemo(() => {
    let lastIndex
    renderMessages.some((val, index, array) => {
      const reverseIndex = array.length - 1 - index
      const message = JSON.parse(_.get(array, `${reverseIndex}.nativeMessage`)).message
      return message?.enableReaction && (lastIndex = reverseIndex)
    })
    return lastIndex
  }, [renderMessages])

  return (
    <div className={classes.container}>
      {renderMessages &&
        renderMessages.map((message, index) => (
          <div ref={setRef(message.chatHistoryId)} key={message.chatHistoryId}>
            <Message
              messages={messages}
              msg={message}
              reverse={reverse}
              highlight={message.chatHistoryId === messageId}
              showConversationId={message?.conversationId !== renderMessages[index - 1]?.conversationId}
              showReactionElementText={index === lastReactionElementIndex}
              searchQuery={searchQuery}
            />
          </div>
        ))}
    </div>
  )
}

MessageGroup.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.array,
  date: PropTypes.string,
  searchQuery: PropTypes.string,
}

export default memo(withStyles(styles)(MessageGroup))
